import React from 'react';
import Modal from 'react-responsive-modal';

export default ({ appStyles, children, isOpen, onClose, center, styles = {}, focusTrapped }) => (
  <Modal
    styles={{
      modal: {
        background: appStyles.cardBackgroundColor,
        borderRadius: 5,
        direction: appStyles.rtl && 'rtl',
        ...(appStyles.bodyFont && { fontFamily: appStyles.bodyFont }),
        ...(appStyles.bodyFontWeight && { fontFamily: appStyles.bodyFontWeight }),
        ...styles.modal,
      },
      overlay: { background: 'rgba(0, 0, 0, 0.5)', ...styles.overlay },
    }}
    blockScroll={false}
    open={isOpen}
    onClose={onClose}
    center={center}
    focusTrapped={focusTrapped}
  >
    {children}
  </Modal>
);
