import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  noop,
  compact,
  identity,
  isEmpty,
  map,
  filter,
  find,
  includes,
  uniqBy,
} from "lodash";
import { search } from "./search";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import {
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Fab,
} from "@material-ui/core";
import { useDebouncedCallback } from "use-debounce";
import usePrevious from "../../../hooks/use-previous";
import ErrorMessage from "../../ErrorMessage";
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import HistoryIcon from "@material-ui/icons/History";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import DebouncedProgressBar from "./debounced-progressbar";
import DebouncedCircularProgress from "./debounced-circular-progress";
import locateMe, { STATUS_CODES } from "../../../utils/locateMe";
import alpha from "color-alpha";
import {
  hasHouseNumberForMapbox,
  stateNameToStateCode,
  ACCURACY_LEVEL,
} from "../../../utils/geoLocation";

/**
 * Geocoder component: connects to Mapbox.com Geocoding API
 * and provides an auto-completing interface for finding locations.
 */
const AddressInputAutocomplete = ({
  endpoint = "https://api.mapbox.com",
  inputPlaceholder = "Search",
  showLoader = true,
  source = "mapbox.places",
  onSuggest = noop,
  onClear = noop,
  focusOnMount = false,
  showInputContainer = true,
  inputValue = "",
  proximity,
  country,
  bbox,
  types,
  limit,
  autocomplete,
  language,
  suggestionsPaperProps,
  onSelect,
  accessToken,
  onInputFocus,
  onInputBlur,
  inputClasses,
  inputTextFieldProps,
  disableUnderline,
  inputPaperProps,
  showLocateMe,
  T = identity,
  appStyles = {},
  onLocateMeSuccess = noop,
  onLocateMeFailure = noop,
  autoLocateMeOnMount,
  accurateOnly,
  previousAddresses,
}) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showHouseNumberInput, setShowHouseNumberInput] = useState();

  const [error, setError] = useState(false);

  const [locatingUser, setLocatingUser] = useState(false);
  const [userLocationTime, setUserLocationTime] = useState(new Date());
  const [usingCurrentLocation, setUsingCurrentLocation] = useState(false);

  const [searchTime, setSearchTime] = useState(new Date());
  const [value, setValue] = useState(inputValue);
  const [inputIsFocused, setInputIsFocused] = useState(false);

  const [houseNumber, setHouseNumber] = useState(null);

  const classes = useStyles();

  const autoSuggestRef = useRef(null);
  const houseNumberRef = useRef(null);

  const prevValue = usePrevious(value);
  const hasNoEffectiveSearchInput = isEmpty(value) || value.length < 4;

  useEffect(() => {
    if (hasNoEffectiveSearchInput) {
      setDefaultResults();
    }
  }, [hasNoEffectiveSearchInput]);

  const focusInput = useCallback(() => {
    const { input = null } = autoSuggestRef.current || {};
    // if (hasNoEffectiveSearchInput) {
    //   setDefaultResults();
    // }
    input && input.focus();
  }, []);

  const focusHouseNumberInput = useCallback(() => {
    const input = houseNumberRef.current || {};
    input && input.focus();
  }, [houseNumberRef.current]);

  useEffect(() => {
    if (accurateOnly && showHouseNumberInput) {
      focusHouseNumberInput();
      onClear();
    }
  }, [showHouseNumberInput, accurateOnly]);

  useEffect(() => {
    if (focusOnMount) {
      focusInput();
    }
  }, [focusOnMount, focusInput]);

  useEffect(() => {
    onSuggest && onSuggest(results);
  }, [results, onSuggest]);

  useEffect(() => {
    showLocateMe &&
      autoLocateMeOnMount &&
      isEmpty(value) &&
      isEmpty(inputValue) &&
      handleLocateMe();
  }, [autoLocateMeOnMount, value, showLocateMe]);

  const houseSearchButtonReady =
    accurateOnly && !isEmpty(houseNumber) && isValidHouseNumber(houseNumber);

  const handleSearchHouseNumber = () => {
    const searchWithHouseNumber = getSearchInputWithHouseNumber({
      houseNumber,
      value,
    });
    setValue(searchWithHouseNumber);
    setHouseNumber("");
    setShowHouseNumberInput(false);
    handleSuggestionsFetchRequested({ value: searchWithHouseNumber });
  };

  const isAccurateAddress = (feature) => {
    return includes(
      // TBD: add INERPULATED
      [ACCURACY_LEVEL.ROOFTOP, ACCURACY_LEVEL.STREET, ACCURACY_LEVEL.POINT],
      feature?.properties?.accuracy,
    );
  };

  const handleClearInput = useCallback(() => {
    console.log("clear input");
    setError(null);
    if (usingCurrentLocation) {
      setUsingCurrentLocation(false);
    } else {
      setValue("");
    }
    if (showHouseNumberInput) {
      setShowHouseNumberInput(false);
    }
    // After clear button is clicked the input should be re-focused automatically.
    focusInput();
    onClear && onClear();
  }, [focusInput, usingCurrentLocation, showHouseNumberInput, onClear]);

  const setDefaultResults = useCallback(() => {
    setResults(
      uniqBy(
        map(
          previousAddresses,
          ({ number, street, city, state, zipCode, ...params }) => ({
            label: [number, street, city, state, zipCode].join(" "),
            savedAddress: {
              number,
              street,
              city,
              state: stateNameToStateCode(state),
              zipCode,
              ...params,
            },
          }),
        ),
        "label",
      ),
    );
  }, [previousAddresses]);

  const handleLocateMe = useCallback(() => {
    setError(null);
    if (locatingUser) {
      return; //bail if already locating user
    }
    console.log("locating user...");
    setLocatingUser(true);

    locateMe()
      .then((coords) => {
        setValue("");
        onLocateMeSuccess({
          userAddress: {
            geoPoint: coords,
            usingCurrentLocation: true,
          },
          coords,
        });
        setLocatingUser(false);
        setUsingCurrentLocation(true);
      })
      .catch((geoLocationError) => {
        console.log({ geoLocationError });

        setLocatingUser(false);
        const errorMessage = getErrorMessageForLocateMeError(
          geoLocationError,
          T,
        );
        setError(errorMessage);
        onLocateMeFailure(geoLocationError);
      });
  }, [locatingUser]);

  const renderInput = useCallback(
    (renderInputProps) => {
      const { ref, inputClasses, ...other } = renderInputProps;

      const inputTextField = (
        <TextField
          fullWidth
          InputProps={{
            disableUnderline,
            inputRef: ref,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
            endAdornment: compact([
              showLocateMe && !usingCurrentLocation && (
                <InputAdornment
                  position="end"
                  className={clsx(classes.noShrink, classes.noGrow)}
                >
                  <Fab
                    disabled={locatingUser}
                    color="primary"
                    style={{ ...appStyles.LocateMeButton }}
                    aria-label={T("Locate me")}
                    variant={
                      locatingUser || value.length > 0 ? "round" : "extended"
                    }
                    size="small"
                    onClick={handleLocateMe}
                  >
                    <DebouncedCircularProgress show={locatingUser} />
                    {!locatingUser && (
                      <>
                        <LocationOnIcon />
                        {value.length === 0 && (
                          <span className={classes.noShrink}>
                            {T("Locate Me")}
                          </span>
                        )}
                      </>
                    )}
                  </Fab>
                </InputAdornment>
              ),
              (value.length > 0 || (showLocateMe && usingCurrentLocation)) && (
                <Grid item xs className={clsx(classes.shrink, classes.noGrow)}>
                  <IconButton
                    aria-label={T("Clear Search Input")}
                    onClick={handleClearInput}
                  >
                    <CancelIcon />
                  </IconButton>
                </Grid>
              ),
            ]),
            classes: inputClasses,
            ...other,
          }}
          {...inputTextFieldProps}
        />
      );

      return (
        <>
          <DebouncedProgressBar show={loading && showLoader} />
          <Paper
            square
            elevation={1}
            className={clsx(classes.inputContainer, {
              inputContainerFocused: inputIsFocused,
            })}
            {...inputPaperProps}
          >
            <Grid container alignItems="center" spacing={6} wrap="nowrap">
              <Grid item xs className={clsx(classes.grow, classes.noShrink)}>
                {inputTextField}
              </Grid>
            </Grid>
          </Paper>
          {showHouseNumberInput && (
            <Grid
              container
              alignItems="center"
              justify="center"
              spacing={2}
              className={classes.streetNumberInputContainer}
            >
              <Grid item>
                <TextField
                  className={classes.streetTextInput}
                  inputRef={houseNumberRef}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HomeWorkIcon />
                      </InputAdornment>
                    ),
                  }}
                  label={T("Enter House Number")}
                  value={houseNumber}
                  onChange={(e) => setHouseNumber(e.target.value)}
                  onKeyDown={(e) => {
                    if (houseSearchButtonReady && e.keyCode == 13) {
                      handleSearchHouseNumber();
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <Fab
                  color="primary"
                  variant="extended"
                  disabled={!houseSearchButtonReady}
                  onClick={handleSearchHouseNumber}
                >
                  <SearchIcon />
                  {T("Search")}
                </Fab>
              </Grid>
            </Grid>
          )}
          {error && (
            <ErrorMessage appStyles={appStyles} warning>
              {error}
            </ErrorMessage>
          )}
        </>
      );
    },
    [
      disableUnderline,
      inputTextFieldProps,
      error,
      showHouseNumberInput,
      loading,
      showLoader,
      classes,
      inputIsFocused,
      inputPaperProps,
      value.length,
      handleClearInput,
      locatingUser,
      showLocateMe,
      usingCurrentLocation,
      houseNumber,
      houseSearchButtonReady,
    ],
  );

  const focusInputHandler = useCallback(
    (e) => {
      setInputIsFocused(true);
      onInputFocus && onInputFocus(e);
    },
    [onInputFocus],
  );

  const blurInputHandler = useCallback(
    (e) => {
      setInputIsFocused(false);
      onInputBlur && onInputBlur(e);
    },
    [onInputBlur],
  );

  const renderSuggestionsContainer = useCallback(
    (options) => {
      const { containerProps, children } = options;
      return (
        <Paper
          {...containerProps}
          square={false}
          elevation={4}
          {...suggestionsPaperProps}
        >
          {children}
        </Paper>
      );
    },
    [suggestionsPaperProps],
  );

  const onResult = useCallback(
    (err, res, st) => {
      // searchTime is compared with the last search to set the state
      // to ensure that a slow xhr response does not scramble the
      // sequence of autocomplete display. in short, it will show the latest
      if (!err && res && res.features && searchTime <= st) {
        setSearchTime(st);

        const suggestions = _.sortBy(
          filter(
            map(res.features, (feature) => ({
              feature: feature,
              label: feature.place_name,
            })),
            (feature) => feature.label,
          ),
          ["feature.address"],
        );

        const hasRelevantAccurateResults = find(
          suggestions,
          ({ feature }) => feature?.address && isAccurateAddress(feature),
        );

        const results = hasRelevantAccurateResults
          ? filter(suggestions, ({ feature }) => isAccurateAddress(feature))
          : suggestions;

        const exactHouseNumberMatch =
          houseNumber &&
          find(
            results,
            ({ label }) =>
              label ===
              getSearchInputWithHouseNumber({
                houseNumber,
                value,
              }),
          );
        if (exactHouseNumberMatch) {
          setResults([]),
            handleSuggestionSelected({}, { suggestion: exactHouseNumberMatch });
        } else {
          setResults(results);
        }

        setLoading(false);
      }
    },
    [searchTime, accurateOnly, houseNumber],
  );

  const searchDebounced = useDebouncedCallback(search, 350);

  const handleSuggestionsFetchRequested = useCallback(
    ({ value, reason }) => {
      setLoading(true);
      const exactMatch = find(results, ({ label }) => label === value);

      if (prevValue === value) {
        setLoading(false);
        if (hasNoEffectiveSearchInput) {
          setDefaultResults();
        }
      } else if (exactMatch && exactMatch.savedAddress) {
        setLoading(false);
        setResults([]);
        if (reason === "input-changed") {
          handleSuggestionSelected({}, { suggestion: exactMatch });
        }
      } else if (isEmpty(value) || value.length < 4) {
        setDefaultResults();
        setLoading(false);
      } else {
        searchDebounced.callback(
          endpoint,
          source,
          accessToken,
          value,
          onResult,
          proximity,
          country,
          bbox,
          types,
          limit,
          autocomplete,
          language,
        );
      }
    },
    [
      bbox,
      results,
      hasNoEffectiveSearchInput,
      country,
      endpoint,
      limit,
      language,
      autocomplete,
      source,
      proximity,
      prevValue,
      onResult,
      types,
      accessToken,
    ],
  );

  /**
   * Parameters Signature:
   * (event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method})
   */
  const handleSuggestionSelected = useCallback(
    (_event, { suggestion }) => {
      console.log("handleSuggestionSelected()", suggestion);
      setValue(suggestion.label);
      if (suggestion.savedAddress) {
        onSelect &&
          onSelect({
            ...suggestion.savedAddress,
            description: suggestion.label,
            savedAddress: true,
          });
      } else {
        if (accurateOnly) {
          if (suggestion.feature.address) {
            onSelect && onSelect(suggestion.feature);
          } else {
            setShowHouseNumberInput(true);
          }
        } else {
          onSelect && onSelect(suggestion.feature);
        }
      }
      // focus on the input after click to maintain key traversal
      // this.inputRef.current && this.inputRef.current.focus()
      return false;
    },
    [onSelect],
  );

  const handleSuggestionsClearRequested = useCallback(() => {
    searchDebounced.cancel();
    setResults([]);
  }, []);

  const handleChange = useCallback((_event, { newValue }) => {
    console.log("handle change");
    setValue(newValue);
  }, []);

  const renderSuggestion = useCallback(
    (suggestion, { query, isHighlighted }) => {
      const matches = match(suggestion.label, query);
      const parts = parse(suggestion.label, matches);
      const displayMissingHouseNumber =
        accurateOnly &&
        !suggestion.savedAddress &&
        !hasHouseNumberForMapbox(suggestion.feature);
      return (
        <MenuItem
          selected={isHighlighted}
          component={Grid}
          direction="column"
          alignItems="flex-start"
        >
          <Grid item container alignItems="center" wrap="nowrap" spacing={1}>
            {suggestion.savedAddress && (
              <Grid item>
                <HistoryIcon />
              </Grid>
            )}
            <Grid item>
              <Typography noWrap variant="subtitle1">
                {parts.map((part, index) => {
                  return part.highlight ? (
                    <span key={String(index)} style={{ fontWeight: 500 }}>
                      {part.text}
                    </span>
                  ) : (
                    <strong key={String(index)} style={{ fontWeight: 300 }}>
                      {part.text}
                    </strong>
                  );
                })}
              </Typography>
            </Grid>
          </Grid>
          {displayMissingHouseNumber && (
            <Grid item>
              <Typography noWrap variant="subtitle2" color="error">
                Missing House Number
              </Typography>
            </Grid>
          )}
        </MenuItem>
      );
    },
    [accurateOnly],
  );

  const getResultValue = useCallback((result) => result.label, []);

  const autoSuggestEl = useMemo(
    () =>
      accessToken ? (
        <Autosuggest
          ref={autoSuggestRef}
          alwaysRenderSuggestions={!showLocateMe && hasNoEffectiveSearchInput}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderInputComponent={renderInput}
          suggestions={results}
          onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={handleSuggestionsClearRequested}
          onSuggestionSelected={handleSuggestionSelected}
          renderSuggestionsContainer={renderSuggestionsContainer}
          getSuggestionValue={getResultValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            placeholder:
              usingCurrentLocation && showLocateMe
                ? T("Current Location")
                : inputPlaceholder,
            value: value,
            onChange: handleChange,
            onFocus: focusInputHandler,
            onBlur: blurInputHandler,
            className: inputClasses,
          }}
        />
      ) : null,
    [
      accessToken,
      blurInputHandler,
      handleChange,
      handleSuggestionSelected,
      focusInputHandler,
      handleSuggestionsFetchRequested,
      inputClasses,
      handleSuggestionsClearRequested,
      renderInput,
      inputPlaceholder,
      renderSuggestionsContainer,
      renderSuggestion,
      results,
      value,
      classes,
      getResultValue,
      usingCurrentLocation,
      hasNoEffectiveSearchInput,
      showLocateMe,
    ],
  );
  return autoSuggestEl;
};

const getErrorMessageForLocateMeError = (error, T) => {
  switch (error) {
    case STATUS_CODES.USER_BLOCK:
      return T(
        "Please enable location services in order to find a location nearby.",
      );

    case STATUS_CODES.TIMEOUT:
      return T("Sorry, it is taking too long to find your location");

    default:
      return null;
  }
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 0,
    position: "relative",
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(0),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: "block",
    marginBottom: 0,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  streetTextInput: {
    color: "inherit",
    "& > *,label": {
      color: "inherit !important",
    },
  },
  inputContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1), // IconButton on right provides sufficient padding
    paddingLeft: theme.spacing(2),
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    overflow: "hidden",
    "&:hover,&:active,&.inputContainerFocused": {
      backgroundColor: theme.palette.background.paper,
    },
    // Maintain a consistent height when IconButton (CancelIcon) is visible.
    minHeight: "64px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 2,
    //...
  },
  grow: {
    flexGrow: 1,
  },
  shrink: {
    flexShrink: 1,
  },
  noGrow: {
    flexGrow: 0,
  },
  noShrink: {
    flexShrink: 0,
  },
  streetNumberInputContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
}));

const houseNumberRegex = /[0-9]{1,5}/;
const isValidHouseNumber = (value) => {
  const houseNumberMatch = value.match(houseNumberRegex);
  return houseNumberMatch && value === houseNumberMatch[0];
};

const getSearchInputWithHouseNumber = ({ houseNumber, value }) =>
  houseNumber + " " + value;

export default AddressInputAutocomplete;
