import React from "react";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import scroll from "scroll";
const page = require("scroll-doc")();
import List from "../List";
import AddressListItem from "./AddressListItem";
import styles from "./index.module.scss";

class AddressesList extends React.Component {
  addressListItemRefs = {};
  registerAddressListItem = key => ref => (this.addressListItemRefs[key] = ref);

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.highlightedSuggestionIndex > -1 &&
      nextProps.highlightedSuggestionIndex !==
        this.props.highlightedSuggestionIndex
    ) {
      this.scrollToFocusItem(
        this.addressListItemRefs[nextProps.highlightedSuggestionIndex],
      );
    }
  }

  scrollToFocusItem = element => {
    if (element) {
      scroll.top(element.parentElement, element.offsetTop - 16, 300);
    }
  };

  render() {
    const { appStyles } = this.props;

    if (isEmpty(this.props.places)) {
      return null;
    }
    const { inputBorderRadius, cardBorderRadius } = appStyles;
    const addressInputBorderRadius = _.isNumber(inputBorderRadius)
      ? inputBorderRadius
      : cardBorderRadius || 0;
    return (
      <List
        rtl={appStyles.rtl}
        classNames={this.props.classNames}
        style={{
          borderBottomLeftRadius: addressInputBorderRadius,
          borderBottomRightRadius: addressInputBorderRadius,
        }}
      >
        {map(this.props.places, (place, index) => (
          <AddressListItem
            T={this.props.T}
            key={place.id}
            refEl={this.registerAddressListItem(index)}
            place={place}
            accurateOnly={this.props.accurateOnly}
            google={this.props.google}
            onSelected={this.props.onSelected}
            geocodeOnSelect={this.props.geocodeOnSelect}
            rtl={appStyles.rtl}
            isHighlighted={this.props.highlightedSuggestionIndex === index}
            onMouseOver={() => this.props.onMouseOverAddressIndex(index)}
            resetHighlightedAddressSelected={
              this.props.highlightedSuggestionIndex === index &&
              this.props.resetHighlightedAddressSelected
            }
            index={index}
            appStyles={appStyles}
            isLast={index + 1 === _.get(this.props.places, "length")}
            showHouseNumberInput={this.props.showHouseNumberInput}
            // googleMapsSessionToken={this.props.sessionToken}
          />
        ))}
      </List>
    );
  }
}

export default AddressesList;
