import React from "react";
import _ from "lodash";
import DebounceInput from "react-debounce-input";
import classnames from "classnames";
import locateMe, { STATUS_CODES } from "../../utils/locateMe";
import SearchIcon from "../icons/Search.svg";
import CompassIcon from "../icons/Compass.svg";
import CancelInputIcon from "../icons/CancelInput.svg";
import styles from "./index.module.scss";
import ErrorMessage from "../ErrorMessage";
import Loader from "../Loader";
import { MISSING_HOUSE_NUMBER, GEOMETRIC_CENTER } from "../../utils/constants";
import Button from "../Button";
import { HouseNumberInput } from "../Inputs";
import HomeIcon from "../icons/Home.svg";
import { convertPlaceToUserAddress } from "../../utils/geoLocation";

const VerticalLine = () => <div className={styles.VerticalLine} />;

class AddressInput extends React.Component {
  state = {
    dirtyInput: !_.isEmpty(this.props.addressInputValue),
    error: null,
    userAddress: null,
    coordsToGeocode: null,
    determiningUserLocation: false,
  };

  currentSearchRequestIndex = -1;

  componentDidMount() {
    if (this.props.autoLocateMeOnMount) {
      this.locateMe();
    }
    if (this.props.focusOnMount) {
      this.input.focus();
    }
    if (this.props.google && !this.props.forSearchOnlyByCode) {
      this.initAutoCompleteService(this.props.google);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.shouldShowHouseNumberInput &&
      this.props.shouldShowHouseNumberInput
    ) {
      this.houseNumberInput.inputElement.focus();
    }

    if (
      !this.state.dirtyInput &&
      this.props.autoLocateMeOnMount &&
      !prevProps.autoLocateMeOnMount
    ) {
      this.locateMe();
    }
    if (
      this.props.google &&
      !prevProps.google &&
      !this.props.forSearchOnlyByCode
    ) {
      if (this.state.coordsToGeocode) {
        this.geocodeUserAddress(this.state.coordsToGeocode, this.props);
      }
      this.initAutoCompleteService(this.props.google);
    }
  }

  locateMe = () => {
    if (this.state.determiningUserLocation) {
      return;
    }
    this.setState({ determiningUserLocation: new Date() }, () => {
      this.props.onResults([]);
      locateMe()
        .then((coords) => {
          this.setState(
            {
              userAddress: {
                geoPoint: coords,
                usingCurrentLocation: true,
              },
              positionToLoad: null,
              error: null,
              dirtyInput: true,
              determiningUserLocation: false,
              coordsToGeocode: null,
            },
            () => {
              _.defer(() => {
                this.props.onLocateMeSuccess({
                  coords,
                  userAddress: this.state.userAddress,
                });
              });
            },
          );
        })
        .catch((error) => {
          console.log({ error });

          if (error === STATUS_CODES.USER_BLOCK) {
            this.setState(
              {
                determiningUserLocation: false,
                error: this.props.T(
                  "Please enable location services in order to find a location nearby.",
                ),
              },
              () => this.props.onLocateMeError(error),
            );
          } else if (error === STATUS_CODES.TIMEOUT) {
            this.setState(
              {
                determiningUserLocation: false,
                ...(!this.props.appStyles.disableTimeoutError && {
                  error: this.props.T(
                    "Sorry, it is taking too long to find your location",
                  ),
                }),
              },
              () => this.props.onLocateMeError(error),
            );
          } else {
            this.setState(
              {
                determiningUserLocation: false,
                error: null,
              },
              () => this.props.onLocateMeError(error),
            );
          }
        });
    });
  };

  geocodeUserAddress = (coords, nextProps) => {
    const { google } = nextProps || this.props;
    const searchRequestIndex = this.currentSearchRequestIndex + 1;
    this.setCurrentSearchRequestIndex(searchRequestIndex);
    const geocoder = new google.maps.Geocoder();
    const { latitude: lat, longitude: lng } = coords;
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      console.log("geocoded location:", { lat, lng });
      if (status === "OK" && !_.isEmpty(results) && results[0]) {
        const userAddress = convertPlaceToUserAddress({
          ...results[0],
          description: results[0].formatted_address,
        });
        this.setState(
          {
            userAddress,
            positionToLoad: null,
            error: null,
            dirtyInput: true,
            determiningUserLocation: false,
            coordsToGeocode: null,
          },
          () => {
            _.defer(() => {
              if (searchRequestIndex === this.currentSearchRequestIndex) {
                this.props.onLocateMeSuccess({ coords, userAddress });
              }
            });
          },
        );
      }
    });
  };

  setInputValue = (value) => {
    this.setState({ dirtyInput: !_.isEmpty(value) }, () => {
      this.props.onChangeAddressInput(value);
      // this.input.value = value;
    });
  };

  initAutoCompleteService = (google) => {
    this.autocompleteService = new google.maps.places.AutocompleteService();
  };

  checkErrors = (place) => {
    if (
      _.includes(place.types, "establishment") ||
      _.includes(place.types, "premise")
    ) {
      return null;
    }

    if (place.structured_formatting.main_text.match(/\d+/g) === null) {
      return MISSING_HOUSE_NUMBER;
    }
  };

  handleAutoCompleteSuggestions = (places, status, searchRequestIndex) => {
    if (status === "OK") {
      places = _.map(places, (place) => ({
        ...place,
        error: this.checkErrors(place),
      }));
      if (searchRequestIndex === this.currentSearchRequestIndex) {
        this.props.onResults(places);
      }
    } else {
      console.error(`autoCompleteSuggestions status: ${status}`);
    }
  };

  getGeocodes = (address, searchRequestIndex) => {
    console.log("geocoding address...", address);
    const getGeocodes = new Promise((resolve, reject) => {
      const Geocoder = new this.props.google.maps.Geocoder();

      Geocoder.geocode({ address }, (geocodes, status) =>
        status === "OK" ? resolve(geocodes) : resolve([]),
      );
    });
    const { shouldAllowInaccurateAddress } = this.props;
    getGeocodes
      .then((results) => {
        if (_.isEmpty(results)) {
          this.setState({
            error: this.props.T(
              "Cannot find the address you entered, please try a different one",
            ),
          });
        } else {
          const allowedAddresses = _.filter(results, (result) => {
            const isAddressInvalid =
              !shouldAllowInaccurateAddress &&
              _.get(result, "geometry.location_type") === GEOMETRIC_CENTER;
            return !isAddressInvalid;
          });
          const geocodes = _.map(allowedAddresses, (result) => {
            if (
              !_.includes(result.types, "establishment") &&
              !_.includes(result.types, "premise") &&
              !_.find(result.address_components, (addressComponent) =>
                _.includes(addressComponent.types, "street_number"),
              )
            ) {
              return {
                ...result,
                id: result.place_id,
                description: result.formatted_address,
                error: MISSING_HOUSE_NUMBER,
              };
            }

            if (_.includes(result.types, "premise")) {
              const premiseAddressComponent = _.find(
                result.address_components,
                (addressComponent) =>
                  _.includes(addressComponent.types, "premise"),
              );

              const streetAddressComponent = _.find(
                result.address_components,
                (addressComponent) =>
                  _.includes(addressComponent.types, "route"),
              );
              if (premiseAddressComponent && !streetAddressComponent) {
                premiseAddressComponent.types = ["route"];
              }
              return {
                ...result,
                id: result.place_id,
                description: result.formatted_address,
              };
            }
            return {
              ...result,
              id: result.place_id,
              description: result.formatted_address,
            };
          });
          if (searchRequestIndex === this.currentSearchRequestIndex) {
            this.props.onResults(geocodes);
          }
        }
      })
      .catch(console.error);
  };

  searchPlaces = (address) => {
    this.setState(
      { userAddress: undefined, error: null, determiningUserLocation: false },
      () => {
        const input = address.trim() || this.input.value.trim();
        const searchRequestIndex = this.currentSearchRequestIndex + 1;
        this.setCurrentSearchRequestIndex(searchRequestIndex);

        if (!this.autocompleteService) {
          this.initAutoCompleteService(this.props.google);
        }

        if (input.length > 0) {
          this.setState({ dirtyInput: true }, () => {
            this.props.onResults([]);

            if (input.length > 3) {
              new Promise((resolve, reject) => {
                // if (!this.props.sessionToken) {
                //   console.warn('Trying to use Google Places AutocompleteService without a session token');
                // }
                this.autocompleteService.getPlacePredictions(
                  {
                    input,
                    // sessionToken: this.props.sessionToken,
                    ...(this.props.types && { types: this.props.types }),
                    location: {
                      lat: () => _.get(this.props.locationBias, "latitude"),
                      lng: () => _.get(this.props.locationBias, "longitude"),
                    },
                    radius: 10000,
                  },
                  (places, status) =>
                    status === "OK" ? resolve(places) : resolve([]),
                );
              })

                .then((results) => {
                  if (_.every(results, (res) => _.isEmpty(res))) {
                    console.log("empty list");
                    this.getGeocodes(input, searchRequestIndex);
                  } else {
                    this.setState({ error: null });
                    const filteredSuggestions = _.compact(
                      _.filter(
                        _.flatten(results),
                        (result) => !_.includes(result.types, "country"),
                      ),
                    );
                    this.handleAutoCompleteSuggestions(
                      filteredSuggestions,
                      "OK",
                      searchRequestIndex,
                    );
                  }
                })
                .catch(console.log);
            }
          });
        } else {
          this.setState({ dirtyInput: !!input.length });
        }
      },
    );
  };

  reset = () => {
    this.props.onResults && this.props.onResults([]);
    this.props.onReset && this.props.onReset();
    this.setState(
      {
        dirtyInput: false,
        userAddress: null,
        searching: false,
        error: null,
      },
      () => {
        this.props.onChangeAddressInput("");
        // this.input.value = '';
      },
    );
  };

  getLocationsByCode = (searchCode) => {
    const { branchesWithCode, setSearchCode } = this.props;
    setSearchCode(searchCode);
    this.setState({ error: null, dirtyInput: true });
    if (_.find(branchesWithCode, { searchCode })) {
      return this.props.onResults(
        [],
        _.filter(branchesWithCode, { searchCode }),
      );
    }
    this.props.onResults([]);
  };

  handleUserInput = (e) => {
    this.props.onChangeAddressInput(e.target.value);
    if (this.props.forSearchOnlyByCode) {
      return this.getLocationsByCode(e.target.value);
    }
    return this.searchPlaces(e.target.value);
  };

  applyInputRef = (ref) => {
    this.input = ref;
    typeof this.props.inputRef == "function" && this.props.inputRef(ref);
  };

  setCurrentSearchRequestIndex = (currentSearchRequestIndex) => {
    this.currentSearchRequestIndex = currentSearchRequestIndex;
  };

  render() {
    const {
      appStyles,
      T,
      showLocateMe,
      classNames,
      handleKeyDown,
      addressInputValue,
      setAddressWithHouseNumber,
      shouldShowHouseNumberInput,
      userEnteredInvalidHouseNumber,
      noSearch,
    } = this.props;
    const addressInputBorderRadius = _.isNumber(inputBorderRadius)
      ? inputBorderRadius
      : cardBorderRadius || 0;

    const {
      actionColor,
      backgroundColor,
      cardBorderRadius,
      inputBorderRadius,
      rtl,
      inputBackgroundColor,
      addressInputFontSize,
      AddressInput = {},
    } = appStyles || {};

    return (
      <div className={classnames(classNames, rtl && "rtl")}>
        <div
          className={classnames(
            styles.AddressInputContainer,
            rtl && styles.RTL,
          )}
          style={{
            backgroundColor: inputBackgroundColor || backgroundColor,
            borderRadius: addressInputBorderRadius,
            ...(this.props.showsAddressesSuggestions &&
            !this.state.determiningUserLocation
              ? {
                  borderWidth: 2,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                }
              : {
                  borderWidth: 1,
                  borderBottomLeftRadius: addressInputBorderRadius,
                  borderBottomRightRadius: addressInputBorderRadius,
                }),
          }}
        >
          <DebounceInput
            style={{
              fontSize: noSearch ? "1rem" : addressInputFontSize,
              ...AddressInput,
            }}
            value={addressInputValue}
            inputRef={this.applyInputRef}
            placeholder={
              showLocateMe
                ? _.get(this.state, "userAddress.usingCurrentLocation")
                  ? T("Current Location")
                  : this.props.placeholder || T("Enter city, address or zip")
                : this.props.placeholder || T("Enter city, address or zip")
            }
            minLength={0}
            debounceTimeout={1000}
            onChange={this.handleUserInput}
            onKeyDown={handleKeyDown}
            disabled={shouldShowHouseNumberInput}
          />

          {!shouldShowHouseNumberInput && !noSearch && (
            <button
              className={styles.SearchButton}
              style={{ color: this.state.dirtyInput ? actionColor : "#d9d9d9" }}
              onClick={(e) => this.searchPlaces(this.input.value)}
            >
              <SearchIcon />
            </button>
          )}

          {(showLocateMe || (!showLocateMe && this.state.dirtyInput)) &&
            !noSearch && <VerticalLine />}
          {showLocateMe ? (
            (this.state.dirtyInput ||
              !_.isEmpty(_.get(this.input, "value")) ||
              this.props.showsAddressesSuggestions) &&
            !this.state.determiningUserLocation ? (
              <button
                onClick={this.reset}
                className={styles.CancelButton}
                style={{ color: actionColor }}
              >
                <CancelInputIcon />
              </button>
            ) : (
              <div
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <button
                  className={styles.LocateMeButton}
                  onClick={this.locateMe}
                  style={{
                    color: "white",
                    ...appStyles.Button,
                    ...appStyles.LocateMeButton,
                    ...{
                      fontSize:
                        ((this.state.determiningUserLocation ? 0.6 : 0.8) *
                          _.get(appStyles.LocateMeButton, "fontSize")) /
                          0.8 || _.get(appStyles.Button, "fontSize", 16) * 0.7,
                    },
                    ...(_.get(appStyles.Button, "border") && {
                      borderWidth: 1,
                    }),
                  }}
                >
                  {T("Locate me")}
                  {this.state.determiningUserLocation ? (
                    <Loader
                      appStyles={appStyles}
                      small
                      style={{
                        color:
                          _.get(appStyles.Button, "color") || "currentColor",
                        margin: 2,
                      }}
                    />
                  ) : !appStyles.noIcons ? (
                    <CompassIcon
                      style={{
                        width: 20,
                        ...(appStyles.rtl
                          ? { marginRight: 2 }
                          : { marginLeft: 2 }),
                      }}
                    />
                  ) : (
                    <span />
                  )}
                </button>
              </div>
            )
          ) : (
            this.state.dirtyInput && (
              <button
                onClick={this.reset}
                style={{ color: actionColor }}
                className={styles.CancelButton}
              >
                <CancelInputIcon />
              </button>
            )
          )}
        </div>
        {userEnteredInvalidHouseNumber && (
          <ErrorMessage appStyles={appStyles}>
            {T(
              "The house number you entered was not found, please try another one",
            )}
          </ErrorMessage>
        )}
        {shouldShowHouseNumberInput && (
          <div className={styles.HouseNumberInputWrapper}>
            <HomeIcon />
            <span style={{ fontSize: "1.1rem", marginBottom: 16 }}>
              {T("Enter House Number: ")}
            </span>
            <HouseNumberInput
              appStyles={appStyles}
              refEl={(ref) => (this.houseNumberInput = ref)}
              T={T}
            />
            <Button
              centered
              appStyles={this.props.appStyles}
              onClick={() => {
                this.houseNumberInput.validate((error, input) => {
                  if (!error) {
                    setAddressWithHouseNumber(input);
                  }
                });
              }}
            >
              {T("Search")}
              <SearchIcon
                className={classnames(styles.SearchButton, rtl && styles.RTL)}
              />
            </Button>
          </div>
        )}
        {this.state.error && (
          <ErrorMessage appStyles={appStyles} warning>
            {T(`${this.state.error}`)}
          </ErrorMessage>
        )}
      </div>
    );
  }
}

export default AddressInput;
