import React from 'react';
import _ from 'lodash';
import { Map, Marker, InfoWindow } from 'google-maps-react';
import { withPrefix } from 'gatsby';

const SERVING_OPTION_TO_SVG = { delivery: 'MapDelivery.svg', pickup: 'MapPickup.svg', sitdown: 'MapSitdown.svg' };

export default class GoogleMapsLoader extends React.Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    center: null,
  };

  componentWillReceiveProps(nextProps) {
    const { locationCoordsClicked, center } = nextProps;

    if (locationCoordsClicked && !_.isEqual(this.state.center, locationCoordsClicked)) {
      this.setState({ center: locationCoordsClicked });
      this.mapObj && this.mapObj.map && this.mapObj.map.panTo(locationCoordsClicked);
    } else if (center && !_.isEqual(this.state.center, center)) {
      this.setState({ center: center });
      this.mapObj && this.mapObj.map && this.mapObj.map.panTo(center);
    }
  }

  onMapClicked = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  render() {
    const { locations, google, height, classNames, iconPath, servingOption } = this.props;
    const firstLocation = _.get(locations, '[0].geoPoint');
    const center =
      this.state.center ||
      (firstLocation && {
        lat: firstLocation.latitude,
        lng: firstLocation.longitude,
      });

    return google ? (
      <div style={{ height, position: 'relative' }} className={classNames}>
        <Map
          initialCenter={center}
          center={center}
          zoom={17}
          className="map"
          style={{ height: '100%', width: '100%' }}
          google={google}
          onClick={this.onMapClicked}
          ref={el => (this.mapObj = el)}
        >
          {this.state.center && <Marker name={'Current Location'} position={this.state.center} />}
          {_.map(locations, (location, key) => (
            <Marker
              key={key}
              name={location.name}
              onClick={this.onMarkerClick}
              position={
                !_.isEmpty(location.geoPoint) && { lat: location.geoPoint.latitude, lng: location.geoPoint.longitude }
              }
              icon={{
                url: withPrefix(`${iconPath}${SERVING_OPTION_TO_SVG[servingOption]}`),
                scaledSize: new google.maps.Size(50, 50),
              }}
            />
          ))}
          <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow}>
            <div>{this.state.selectedPlace.name}</div>
          </InfoWindow>
        </Map>
        <div id="placeDetails" />
      </div>
    ) : null;
  }
}
